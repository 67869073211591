import { Heading } from '@enterprise-ui/canvas-ui-react'
import { Grid, LinearProgress, Paper } from '@mui/material'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import { useAuth } from '@praxis/component-auth'
import { isEmpty } from 'lodash'
import { Picture } from 'nicollet-react'
import { useEffect, useMemo, useState } from 'react'

import PublishingApi from '../../api/creative-hub/PublishingApi'
import {
  GROUPED_OFFER_CATEGORY,
  MEDIA_UNIT_TYPE,
  CREATIVEHUB_GO_PREFIX,
} from '../util/GroupedOfferUtils'

import { FullPageImageModal } from './FullPageImageModal'
import GridCellExpand from './GridCellExpand'

const theme = createTheme({
  palette: {
    primary: {
      main: '#33691e',
    },
    secondary: {
      main: '#bdbdbd',
    },
  },
})

const grey = '#888888'
const useStyles = makeStyles({
  button: {
    margin: '25px 25px 0 0',
    padding: '14px 0 14px 0',
    width: '100%',
  },
  buttonGroup: {
    '& .C-ButtonGroup__ListItem': {
      marginRight: '4%',
      width: '38%',
    },
  },
  gridItem: {
    '& .Mui-disabled': {
      '& .MuiInputBase-input': {
        color: grey,
      },
      color: grey,
    },
    '& .MuiInputBase-formControl': {
      margin: '20px 0',
    },
  },
  header: {
    fontWeight: 'bold',
  },
  heading: {
    margin: '30px 0 40px 0',
  },
  limitText: {
    maxWidth: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listingsContainer: {
    '& .MuiDataGrid-colCellTitle': {
      fontSize: '13px',
      fontWeight: 900,
    },
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgba(178, 216, 178, 0.5);',
    },
    '& .row--deactivated': {
      backgroundColor: '#fce44a80',
    },
    padding: '0 4px',
  },
  mainImportantContainer: {
    '& .MuiContainer-maxWidthLg': {
      maxWidth: '100%',
    },
    maxWidth: '100%',
    padding: '0px 24px',
  },
  marektingIdForm: {
    '& .MuiFormLabel-root': {
      margin: '20px 0 0 0',
    },
    margin: '30px 0 0 0',
    width: '80%',
  },
  margin: 0,
  offerFormInput: {
    '& .MuiFormLabel-root': {
      margin: '20px 0 0 0',
    },
    width: '80%',
  },
  padding: 0,
})

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value ? params.value.toString() : ''}
      width={params.colDef.width}
    />
  )
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}
export const ViewGroupedOffers = (props) => {
  const auth = useAuth()
  const { session } = auth
  const classes = useStyles()
  const [groupedOffers, setGroupedOffers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refreshData, setRefreshData] = useState(true)
  const [showFullImage, setShowFullImage] = useState(false)
  const [activeUrl, setActiveUrl] = useState('')
  const mediaUnitApi = useMemo(() => new PublishingApi(), [])
  const openFullImage = (url, shouldOpen) => {
    setActiveUrl(url)
    setShowFullImage(shouldOpen)
  }

  const columns = [
    {
      field: 'primary_image',
      headerName: 'Image',
      renderCell: (params) => {
        let url = params.row['primary_image']
        return (
          <Picture
            images={[
              {
                imageUrl: url + '?wid=80&hei=80',
                media: '0px',
              },
            ]}
            width="40px"
            height="40px"
            onClick={() => openFullImage(url, true)}
          />
        )
      },
      width: 100,
    },
    {
      field: 'go_id',
      headerName: 'Grouped Offer Id',
      renderCell: (params) => {
        let goId = params.row['go_id']
        let mediaUnitId = params.row['id']
        let url = `/groupedOffers/view/${mediaUnitId}`
        return (
          <a href={url} rel="noreferrer" target="_blank">
            {goId}
          </a>
        )
      },

      width: 200,
    },
    {
      field: 'offer_ids',
      headerName: 'Offer Ids',
      renderCell: renderCellExpand,
      width: 300,
    },
    {
      field: 'boosted_tcins',
      headerName: 'Boosted Tcins',
      renderCell: renderCellExpand,
      width: 300,
    },
    {
      edit: false,
      field: 'created_by',
      headerName: 'Created By',
      renderCell: renderCellExpand,
      width: 200,
    },
    {
      field: 'body_copy',
      headerName: 'Body Copy',
      renderCell: renderCellExpand,
      width: 200,
    },
    {
      edit: false,
      field: 'feature_offer',
      headerName: 'Feature Offer',
      renderCell: renderCellExpand,
      width: 100,
    },
  ]

  useEffect(() => {
    if (refreshData) {
      setIsLoading(true)
      mediaUnitApi
        .getMediaUnitsLite(MEDIA_UNIT_TYPE, GROUPED_OFFER_CATEGORY)
        .then((response) => {
          setGroupedOffers(response)
        })
        .finally(() => {
          setRefreshData(false)
          setIsLoading(false)
        })
    }
  }, [session.userInfo, refreshData, mediaUnitApi])

  return (
    <div className={classes.mainImportantContainer}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {isLoading ? (
            <div data-testid="loadingContainer" className="hc-pa-normal">
              <LinearProgress />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Heading size={3} className={classes.heading}>
                  Grouped Offers
                </Heading>
                {isEmpty(groupedOffers) ? (
                  <div
                    data-testid="noListingsContainer"
                    className="hc-pa-normal"
                  >
                    No Grouped Offers found
                  </div>
                ) : (
                  <div
                    data-testid="offersContainer"
                    className={`hc-pa-normal ${classes.listingsContainer}`}
                  >
                    <Paper
                      style={{
                        height: 700,
                        width: '100%',
                      }}
                    >
                      <DataGrid
                        components={{
                          Toolbar: CustomToolbar,
                        }}
                        rows={groupedOffers?.map((mediaUnit = {}) => ({
                          body_copy: mediaUnit.body_copy,
                          boosted_tcins: mediaUnit.boosted_tcins,
                          created_by: mediaUnit.created_by,
                          feature_offer: mediaUnit.feature_offer,
                          go_id: CREATIVEHUB_GO_PREFIX + '-' + mediaUnit.go_id,
                          id: mediaUnit.id,
                          media_unit: mediaUnit,
                          offer_ids: mediaUnit.offers
                            .map((offer) => offer.ivy_offer_id)
                            .join(', '),
                          primary_image: mediaUnit.images[0].external_url,
                        }))}
                        columns={columns}
                        rowsPerPageOptions={[20, 100, 500]}
                        pageSize={100}
                      />
                    </Paper>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
          <FullPageImageModal
            imageUrl={activeUrl}
            showFullImage={showFullImage}
            setShowFullImage={setShowFullImage}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}
