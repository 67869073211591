import appConfig from '../../config/appConfig'

const config = appConfig()

export const umbrellaSearch = (
  umbrellas = [],
  search,
  filterPyramids = [],
  sortHasBeenClicked,
  ascendingUmbrella,
  sortDateClicked,
  ascendingDateClicked,
  umbrellaIsDropped,
  standardUmbrellasHidden
) => {
  const filterListPryramidNames = filterPyramids.map((pyr) =>
    getGroupNameValues(pyr)
  )

  const displayUmbrellas = umbrellas.map((umbrella) => {
    if (!umbrella.forecast) {
      umbrella.forecast = {
        total_dmarkdown: '',
        total_promo_sales: '',
        total_promo_sales_units: '',
      }
    }
    return umbrella
  })

  let filteredUmbrellas = displayUmbrellas

  if (search !== '' || filterPyramids.length > 0) {
    const standardSearch = search?.toUpperCase()
    const umbrellaPyramidFilter = (umbrella) => {
      if (filterPyramids.length === 0) {
        return true
      }

      if (!umbrella.merchant_category) {
        return false
      }

      if (!umbrella.forecast) {
        umbrella.forecast = {
          total_dmarkdown: '',
          total_promo_sales: '',
          total_promo_sales_units: '',
        }
      }

      return filterListPryramidNames.includes(
        umbrella?.merchant_category?.group_name
      )
    }

    filteredUmbrellas = displayUmbrellas
      .filter(
        (umbrella) =>
          umbrella?.name?.toUpperCase().includes(standardSearch) ||
          umbrella?.priority?.name?.toUpperCase().includes(standardSearch)
      )
      .filter(umbrellaPyramidFilter)
  }

  if (sortHasBeenClicked) {
    if (ascendingUmbrella) {
      filteredUmbrellas.sort(
        (a, b) => a.forecast?.total_promo_sales - b.forecast?.total_promo_sales
      )
    } else {
      filteredUmbrellas.sort(
        (a, b) => b.forecast?.total_promo_sales - a.forecast?.total_promo_sales
      )
    }
  }
  if (sortDateClicked) {
    ascendingDateClicked
      ? filteredUmbrellas
          .sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
          .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
      : filteredUmbrellas
          .sort((a, b) => new Date(b.end_date) - new Date(a.end_date))
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
  }

  return standardUmbrellasHidden
    ? filterUmbrellasByTier(filteredUmbrellas)
    : filteredUmbrellas
}

const getGroupNameValues = (pyramidAbbrev) => {
  switch (pyramidAbbrev) {
    case 'a&a':
      return 'APPAREL/ACCESS'

    case 'bty':
    case 'ess':
      return 'ESSENTIALS & BEAUTY'

    case 'hrdl':
      return 'HARDLINES'

    case 'f&b':
      return 'FOOD/BEVERAGE'

    case 'home':
      return 'HOME'

    default:
      return ''
  }
}

export const updateUmbrellaReqeust = (umbrella, storyId) => {
  const umbrellaRequest = {
    id: umbrella.planning_umbrella_id,
    story_id: storyId,
    umbrella_id: umbrella.ivy_umbrella_id,
    umbrella_order: umbrella.umbrella_order,
  }

  return umbrellaRequest
}

export const createUmbrellaRequest = (umbrella, storyId, umbrellaOrder) => {
  const umbRequestArray = []
  for (let i = 0; i < umbrella?.length; i++) {
    umbRequestArray.push({
      ivy_umbrella_id: umbrella[i].ivy_umbrella_id,
      umbrella_name: umbrella[i].name,
      umbrella_order: umbrellaOrder + i,
      umbrella_status: umbrella[i].status,
    })
  }
  const umbrellaRequest = {
    story_id: storyId,
    umbrella_request: umbRequestArray,
  }
  return umbrellaRequest
}

export const getLinkedUmbrellaNames = (linkedUmbrellas) => {
  let LinkedUmbrellas = ''
  for (let i = 0; i < linkedUmbrellas?.length; i++) {
    LinkedUmbrellas += `'` + linkedUmbrellas[i].name + `' `
  }

  return LinkedUmbrellas
}

export const copyUmbrellaLink = ({ ivy_umbrella_id, name }) =>
  window.navigator.clipboard.write([
    new window.ClipboardItem({
      'text/html': new Blob(
        [
          `<a href="${config.ivy.uiUrl}umbrella/${ivy_umbrella_id}">${name}</a>`,
        ],
        { type: 'text/html' }
      ),
    }),
  ])

export const appendForecastObjectWhenEmpty = (umbrellas = []) => {
  umbrellas.map((umb) => {
    if (umb.forecast === undefined) {
      umb.forecast = {
        total_dmarkdown: '',
        total_promo_sales: '',
        total_promo_sales_units: '',
      }
    }
    return umbrellas
  })
}

export const filterUmbrellasByChannel = (
  umbrellas = [],
  selectedMarketingChannelFilter = []
) => {
  if (selectedMarketingChannelFilter.length > 0) {
    let displayedUmbrellas = []
    umbrellas.forEach((umbrella = {}) => {
      umbrella.channels?.forEach((channel = {}) => {
        selectedMarketingChannelFilter.forEach((channelFilter = {}) => {
          if (
            channelFilter.label.includes(channel.channel_name) &&
            !displayedUmbrellas.includes(umbrella)
          ) {
            displayedUmbrellas.push(umbrella)
          }
        })
      })
    })
    return displayedUmbrellas
  }
  return umbrellas
}

export const checkedChannels = (
  channel,
  assigned,
  umbrellaCount,
  umbrellas
) => {
  if (!assigned || !umbrellaCount || !umbrellas) {
    return false // return false if any required attribute is missing
  }

  let count = 0
  assigned.forEach((el) => {
    if (el?.channel_id === channel) {
      count++
    }
  })
  umbrellaCount[channel] = count || undefined

  return umbrellaCount[channel] === umbrellas.length
}

export const trackSelectedChannel = (
  channels,
  umbrellaCount,
  umbrellas,
  setCurrentSelectedChannel
) => {
  if (!channels || !umbrellaCount || !umbrellas) {
    setCurrentSelectedChannel({})
    return
  }

  let obj = {}
  if (channels) {
    channels.forEach((channel) => {
      if (channel && channel.channel_id !== undefined) {
        obj[channel.channel_id] =
          umbrellaCount[channel.channel_id] === umbrellas.length
      }
    })
  }
  setCurrentSelectedChannel(obj)
}

export const filterUmbrellasByTier = (umbrellas = []) =>
  umbrellas.filter((umbrella = {}) => umbrella.priority?.tier !== 'STANDARD')

export const standardUmbrellas = (umbrellas = []) =>
  umbrellas.filter((umbrella = {}) => umbrella.priority?.tier === 'STANDARD')
