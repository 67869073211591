import {
  Card,
  Grid,
  GridContainer,
  Heading,
  Image,
  Spinner,
  Tooltip,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { faFilePdf, faLink, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { debounce } from 'lodash'
import moment from 'moment'
import { useContext, useState, useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import MerchandiseApi from '../../api/creative-hub/MerchandiseApi'
import ProjectApi from '../../api/creative-hub/ProjectApi'
import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import { BreadcrumbContext } from '../../context/Context'
import {
  groupArtPatchOffers,
  groupOffersByArtPatchId,
} from '../../creativeHubGlobal/util/LeadersUtil'
import { getDivisionName } from '../../creativeHubGlobal/util/MerchandiseUtil'
import {
  checkForAllMarkets,
  makeOfferDetails,
} from '../../creativeHubGlobal/util/OfferUtil'
import { isUmbrellaEnterpriseLinked } from '../../creativeHubGlobal/util/UmbrellaUtil'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'
import {
  handleFireflyClickInteraction,
  handleFireflyInit,
  handleFireflySuccess,
} from '../../util/FireflyHelper'
import ItemApi from '../api/projectExecution/ItemApi'
import CreativeSummaryPDF from '../creativeSummary/CreativeSummaryPDF'
import { assethubImageCheck, promoPrice } from '../util/ItemUtil'
import './../scss/CreativeSummaryPage.scss'

const CreativeSummaryPage = ({ session = {} }) => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const [summaryData, setSummaryData] = useState([])
  const [linkedUmbrellas, setLinkedUmbrellas] = useState([])
  const [divisions, setDivisions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const projectId = useParams()?.projectId
  const storyId = useParams()?.storyId
  const umbrellaId = useParams()?.umbrellaId
  const umbrellaOrder = useParams()?.umbrellaOrder
  const itemApi = useMemo(() => new ItemApi(), [])
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const projectApi = useMemo(() => new ProjectApi(), [])
  const umbrellaApi = useMemo(() => new UmbrellaApi(), [])

  const [apiTimedout, setApiTimedout] = useState(false)
  const [myCurrentPage, setMyCurrentPage] = useState(1)

  const currentParams = Object.fromEntries(query)
  const channelIds = currentParams?.channelIds || []
  const setToaster = ToastProvider.useToaster()

  const viewName = 'Merchants: Creative Summary'
  const [nextLoading, setNextLoading] = useState(false)
  const umberallaRef = useRef(null)
  const [lastPage, setLastPage] = useState(false)

  useEffect(() => {
    projectApi.fetchProjectDetails(projectId).then((data) => {
      setBreadcrumbContext({
        row: data,
        setBreadcrumbContext,
      })
      umbrellaApi
        .getEnterpriseUmbrellas(data.project_id, data.start_date, data.end_date)
        .then((response) => {
          setLinkedUmbrellas(response)
        })
    })
    handleFireflyInit('pageload', viewName, 'success', session)
    if (umbrellaId) {
      itemApi
        .getUmbrellaCreativeSummary(projectId, storyId, umbrellaId, channelIds)
        .then((data) => {
          setSummaryData(sortSummaryData(data))
          handleFireflySuccess('pageload', viewName, 'success', session)
        })
        .catch((e) => {
          console.log(e)
          setSummaryData([])
        })
    } else {
      itemApi
        .getCreativeSummary(projectId, storyId, channelIds)
        .then((data) => {
          handleSummaryResponse(data)
          handleFireflySuccess('pageload', viewName, 'success', session)
        })
        .catch((e) => {
          console.log(e)
          handleSummaryResponse([])
        })
    }
    merchandiseApi.getDivisions().then((response) => setDivisions(response))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 10 >=
        document.documentElement.offsetHeight
      ) {
        if (!lastPage && lastPage !== undefined) {
          if (isLoading) return
          handleRequest(myCurrentPage + 1)
          setMyCurrentPage((prev) => prev + 1)
        }
      }
    }, 500)

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myCurrentPage, lastPage, isLoading])

  useEffect(() => {
    const pageHandleRequest = handleRequest(myCurrentPage + 1)
    const updatedHeight = debounce(() => {
      if (!isLoading && umberallaRef.current) {
        if (
          umberallaRef.current.clientHeight < window.innerHeight &&
          !lastPage
        ) {
          setMyCurrentPage((prev) => prev + 1)
        }
      }
    }, 1000)

    if (pageHandleRequest !== undefined) {
      updatedHeight()

      const resizeObv = new ResizeObserver(updatedHeight)
      if (
        umberallaRef.current &&
        umberallaRef.current.clientHeight < window.innerHeight
      ) {
        resizeObv.observe(umberallaRef.current)
      }
      return () => {
        resizeObv.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [umberallaRef?.current?.clientHeight < window.innerHeight])

  const handleRequest = (num) => {
    if (isLoading) return
    setNextLoading(true)
    itemApi
      .getCreativeSummary(projectId, storyId, channelIds, num)
      .then((data) => {
        handleSummaryResponse(data, num)
        setNextLoading(false)
      })
      .catch((error) => {
        setNextLoading(false)
      })
  }

  const handleSummaryResponse = (data, page = 1) => {
    if (data === 503 || data === 500) {
      setApiTimedout(true)
      setToaster({
        color: 'error',
        headingText: 'Error',
        isVisible: true,
        message: `Sorry, this Mktg Story PDF is unavailable or page error.  Please use Umbrella PDFs or contact support.`,
      })
    }

    setIsLoading(true)

    let updatedData = sortSummaryData(data)

    if (page === 1) {
      setSummaryData(updatedData)
    } else {
      const umbrellas = updatedData?.story?.umbrellas || []

      setSummaryData((prevState) => ({
        ...prevState,
        story: {
          umbrellas: [...(prevState.story?.umbrellas || []), ...umbrellas],
        },
      }))
    }
  }

  const convertPDFname = (priorityType, priorityOrder) =>
    priorityType === 'ENT'
      ? `EGO${priorityOrder}`
      : `${priorityType}_Priority${priorityOrder}`

  const sortSummaryData = (data = {}) => {
    const {
      project_description: projectDescription = '',
      project_name: projectName = '',
      priority_name: pyramidName = '',
      priority_type: pyramidType = '',
      story = {},
    } = data
    const umbrellas = story?.umbrellas || []
    // eslint-disable-next-line
    const sortedUmbrellas = umbrellas?.sort(
      (a, b) => a.umbrella_order - b.umbrella_order
    )

    for (let i = 0; i < sortedUmbrellas.length; i++) {
      const offers = umbrellas[i].offers
      // eslint-disable-next-line
      const sortedOffers = offers.sort((a, b) => a.offer_order - b.offer_order)

      for (let j = 0; j < sortedOffers.length; j++) {
        const items = offers[j].items

        if (sortedOffers[j].markets?.length) {
          sortedOffers[j].markets = [
            checkForAllMarkets(
              sortedOffers[j]?.markets,
              sortedOffers[j]?.location_group_id,
              sortedOffers[j]?.price_patches,
              sortedOffers[j]?.mixed_discount
            ),
          ]
        }

        // eslint-disable-next-line
        const sortedItems = items.sort((a, b) => a.item_order - b.item_order)
      }

      const { followers = [], leaders = [] } = groupArtPatchOffers({
        displayOffers: sortedOffers,
        projectDescription,
        projectName,
        pyramidName,
        pyramidNameId: {},
        pyramidType,
        storyId: story.story_id,
        storyName: story.story_name,
        umbrella: umbrellas[i],
      })

      umbrellas[i].offers = leaders
      umbrellas[i].artPatchedOffers = groupOffersByArtPatchId(followers)
    }

    setIsLoading(false)
    let res = data?.meta_data?.last

    setLastPage(res)

    return data
  }

  return (
    <div className="creativeSummaryPage">
      {isLoading && !apiTimedout ? (
        <div className="pyramidSpinner">
          {' '}
          <Spinner expanded />
        </div>
      ) : (
        <div className="umbrellaUnits" ref={umberallaRef}>
          <Grid.Container>
            <Grid.Item xs={10} className="creative-header">
              <Heading>{'Creative Summary'}</Heading>
            </Grid.Item>
            <Grid.Item xs={2} className="pdf-download">
              <PDFDownloadLink
                document={
                  <CreativeSummaryPDF
                    summaryData={summaryData}
                    divisions={divisions}
                    projectId={projectId}
                  />
                }
                fileName={
                  moment(summaryData?.project_start_date).format('YYYYMMDD') +
                  '_' +
                  convertPDFname(
                    summaryData?.priority_type?.toUpperCase(),
                    summaryData?.priority_order
                  ) +
                  '_Story' +
                  summaryData?.story?.story_order +
                  (umbrellaId ? '_Umbrella' + umbrellaOrder : '')
                }
                onClick={() =>
                  handleFireflyClickInteraction(
                    'pageload',
                    'Merchants: Creative Summary PDF',
                    'success',
                    session
                  )
                }
              >
                {({ loading }) =>
                  loading ? (
                    <span className="labelled-loading">
                      <Spinner dense={true} />
                    </span>
                  ) : (
                    <FontAwesomeIcon
                      className="labelledIcon"
                      icon={faFilePdf}
                      size="2x"
                      id="summaryPdf"
                      data-testid="summaryPdf"
                    />
                  )
                }
              </PDFDownloadLink>
            </Grid.Item>
          </Grid.Container>
          {/* <PDFViewer name="smple" height="1200" width="100%" showToolbar>
            <CreativeSummaryPDF summaryData={summaryData} />
          </PDFViewer> */}
          <div className="marketing-story-title">
            <h3 className="summary-item--title">
              Mktg Story:{' '}
              <span className="summary-item--value">
                {summaryData?.story?.story_name}
              </span>
              Project:{' '}
              <span className="summary-item--value">
                {summaryData?.project_description}
              </span>
              Start Date:{' '}
              <span className="summary-item--value">
                {summaryData.project_start_date
                  ? moment(
                      summaryData?.project_start_date
                        ? summaryData.project_start_date.split('.')[0]
                        : ''
                    ).format('MM/DD/YY')
                  : ' '}
              </span>
              Priority Name:{' '}
              <span className="summary-item--value">
                {summaryData?.priority_name}
              </span>
            </h3>
          </div>
          {summaryData?.story?.umbrellas.length > 0 ? (
            <>
              {summaryData?.story?.umbrellas?.map((umbrella, i) => (
                <div
                  className="umbrella-container"
                  key={i + '-' + umbrella.umbrella_id}
                  index={i}
                >
                  <Grid.Container
                    data-testid="offer"
                    spacing="none"
                    align="flex-start"
                    xs={12}
                  >
                    <div className="summary-item--content">
                      <h5 className="summary-item--title">
                        Umbrella {umbrella.umbrella_order}:{' '}
                        <span className="summary-item--value">
                          {isUmbrellaEnterpriseLinked(
                            linkedUmbrellas,
                            umbrella.ivy_umbrella_id
                          ) ? (
                            <Tooltip
                              content="This umbrella is also linked to an Enterprise Guest Outcome"
                              location="top-left"
                            >
                              <FontAwesomeIcon
                                icon={faLink}
                                className="fa-icon linked"
                                size="lg"
                              />
                            </Tooltip>
                          ) : null}
                          {umbrella.key ? (
                            <FontAwesomeIcon
                              icon={faKey}
                              className="fa-duotone key"
                              size="lg"
                              color="black"
                            />
                          ) : null}
                          {umbrella.name}{' '}
                        </span>
                        <div>
                          Pyramid:{' '}
                          <span className="summary-item--value">
                            {umbrella.merchant_category?.group_name}{' '}
                          </span>
                        </div>
                        <div>
                          Mktg Channels:{' '}
                          {umbrella.channels?.map((chan, i) => (
                            <span
                              className="summary-item--value"
                              key={
                                umbrella.id + '-' + chan.channel_name + '--' + i
                              }
                              index={i}
                            >
                              {chan.channel_name}
                              {'; '}
                            </span>
                          ))}
                        </div>
                        <div>
                          Promo Message:{' '}
                          <div
                            className="summary-item--value"
                            style={{ whiteSpace: 'pre' }}
                          >
                            {umbrella.promo_message}
                          </div>
                        </div>
                      </h5>
                    </div>
                    {umbrella.offers?.length > 0 ? (
                      <>
                        {umbrella.offers?.map((offer, i) => (
                          <>
                            <OfferSummary
                              divisions={divisions}
                              offer={offer}
                              index={i}
                            />
                            {umbrella.artPatchedOffers[offer.id]?.length > 0 ? (
                              <>
                                {umbrella.artPatchedOffers[offer.id]?.map(
                                  (artPatchOffer = {}, i) => (
                                    <OfferSummary
                                      divisions={divisions}
                                      offer={artPatchOffer}
                                      index={-1}
                                    />
                                  )
                                )}
                              </>
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : (
                      <Grid.Item className="no-items-found" xs={10}>
                        No Offers Found.
                      </Grid.Item>
                    )}
                  </Grid.Container>
                </div>
              ))}
              <div className="loadingSpinner">
                {nextLoading ? (
                  <div className="pyramidSpinner">
                    {' '}
                    <Spinner expanded />
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <Grid.Item className="no-items-found" xs={10}>
              No Umbrellas Found.
            </Grid.Item>
          )}
        </div>
      )}
    </div>
  )
}

const OfferSummary = ({ divisions = [], offer = {}, index = 0 }) => (
  <Grid.Container
    data-testid="offer-container"
    spacing="none"
    align="center"
    xs={10}
    className="offer-container"
    index={index}
    key={index + '--' + offer.id}
  >
    <Grid.Item
      xs={10}
      className={`summary-item--content ${
        offer.status === 'deactivated' ? 'deactivated' : ''
      }`}
    >
      <h5 className="summary-item--title">
        <div>
          {offer.ivy_offer_id === -1 ? 'Placeholder ' : ''} Offer{' '}
          {index > -1 ? index + 1 : ''}:{' '}
          <span className="summary-item--value">
            {offer.is_key ? (
              <FontAwesomeIcon
                icon={faKey}
                className="fa-duotone key"
                size="lg"
                color="black"
              />
            ) : null}
            {offer.name}
          </span>
        </div>
        <div>
          Ivy Offer Id:
          <span className="summary-item--value">{offer.ivy_offer_id}</span>
        </div>
        <>
          {offer.division ? (
            <div>
              Division:{' '}
              <span className="summary-item--value">
                {getDivisionName(divisions, offer.division)}
              </span>
            </div>
          ) : null}
        </>
        <>
          <div>
            {`Offer Details:  `}
            <span className="summary-item--value">
              {makeOfferDetails(offer.circle_offer, offer.mass_offer)}
            </span>
          </div>
        </>
        {offer.ivy_offer_id !== -1 ? (
          <>
            <div>
              Promo $:{' '}
              <span className="summary-item--value">
                {' '}
                {offer.promo_content}
              </span>
            </div>
            <div>
              Sale Callout:{' '}
              <span className="summary-item--value">{offer.sale_handle}</span>
            </div>
            <div>
              Mktg Channels:{' '}
              {offer.channels?.map((chan, i) => (
                <span
                  className="summary-item--value"
                  index={i}
                  key={offer.id + '-offer-channel-' + chan.channel_name}
                >
                  {chan.channel_name}
                  {'; '}
                </span>
              ))}
            </div>
            <div>
              Copy Direction:{' '}
              <span className="summary-item--value"> {offer.copy}</span>
            </div>
            <div>
              Merch Notes:{' '}
              <span className="summary-item--value">{offer.merch_notes}</span>
            </div>
            <div>
              Markets:{' '}
              {offer.markets?.map((market, i) => (
                <span
                  className="summary-item--value"
                  index={i}
                  key={offer.id + '-offer-markets-' + i}
                >
                  {market}
                  {'; '}
                </span>
              ))}
            </div>
            {!offer.mixed_discount && offer.price_patches?.length > 0 ? (
              <>
                <Grid.Item
                  className=""
                  xs={12}
                  data-testid="price-patch-header"
                >
                  <h4>Price Patching:</h4>
                </Grid.Item>
                {offer.price_patches?.map((patch = {}, index) => (
                  <>
                    {patch.price_markets?.length > 0 ? (
                      <GridContainer
                        data-testid="offer"
                        spacing="none"
                        align="center"
                        xs={12}
                        key={'patch-' + index}
                      >
                        <Grid.Item className="" xs={1}>
                          <h4>Price</h4>
                        </Grid.Item>
                        <Grid.Item className="summary-item--value" xs={1}>
                          {patch.price_patch}
                        </Grid.Item>
                        <Grid.Item className="" xs={1}>
                          <h4>Markets</h4>
                        </Grid.Item>
                        <Grid.Item className="summary-item--value" xs={9}>
                          {patch.price_markets.join('; ')}
                        </Grid.Item>
                      </GridContainer>
                    ) : null}
                  </>
                ))}
              </>
            ) : null}
          </>
        ) : null}
      </h5>
    </Grid.Item>
    {offer.items.length > 0 ? (
      <Grid.Container
        spacing="none"
        justify="space-around"
        xs={10}
        className="item-container"
      >
        {offer.items?.map((item, i) => (
          <Grid.Item key={i + '-item-' + item.id} className="image-card" xs={2}>
            <Card>
              {assethubImageCheck(
                item.primary_image_url,
                item.placeHolderImage
              ) && <div align="center">AssetHub Image</div>}
              <Image
                alt="Item image is not available"
                height="200px"
                src={
                  item.primary_image_url
                    ? item.primary_image_url
                    : noImagePlaceholder
                }
                width="100%"
              />

              <div className="image-content-details">
                <h5 className="summary-item--title">
                  {item.markets?.length > 0 ? (
                    <div>
                      Markets:{' '}
                      <span className="summary-item--value">
                        {item.markets.join('; ')}
                      </span>
                    </div>
                  ) : null}
                  {item.art_patch_copy ? (
                    <div>
                      Art Patch Copy:{' '}
                      <span className="summary-item--value">
                        {item.art_patch_copy}
                      </span>
                    </div>
                  ) : null}
                  <div>
                    DPCI:{' '}
                    <span className="summary-item--value">{item.dpci}</span>
                  </div>
                  <div>
                    TCIN:{' '}
                    <span className="summary-item--value">{item.tcin}</span>
                  </div>
                  <div>
                    Item Desc.:{' '}
                    <span
                      className="summary-item--value"
                      dangerouslySetInnerHTML={{
                        __html: item.item_name,
                      }}
                    />
                  </div>
                  <div>
                    Reg. Callout:{' '}
                    <span className="summary-item--value">
                      {item?.reg_callout === true ? 'Yes' : 'No'}
                    </span>{' '}
                  </div>
                  <div>
                    Reg.:{' '}
                    <span className="summary-item--value">
                      {item.reg_price}
                    </span>
                  </div>
                  {offer.mixed_discount ? (
                    <div>
                      Promo $ (Mixed Discount):{' '}
                      <span className="summary-item--value">
                        {promoPrice(offer.price_patches, item)}
                      </span>
                    </div>
                  ) : null}
                  <div>
                    Image Status:{' '}
                    <span className="summary-item--value">
                      {item.image_type}
                    </span>
                  </div>
                  <div>
                    Merch Product Notes:{' '}
                    <span className="summary-item--value">
                      {item.merch_notes}
                    </span>{' '}
                  </div>
                </h5>
              </div>
            </Card>
          </Grid.Item>
        ))}
      </Grid.Container>
    ) : (
      <>
        {offer.ivy_offer_id !== -1 ? (
          <Grid.Item className="no-items-found" xs={10}>
            No Featured Items Found.
          </Grid.Item>
        ) : null}
      </>
    )}
  </Grid.Container>
)

export default CreativeSummaryPage
