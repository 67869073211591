import appConfig from '../config/appConfig'

const config = appConfig()
export function isAdmin(roles) {
  if (!roles) return false
  return (
    roles.includes(config.adminGroups[0]) ||
    roles.includes(config.adminGroups[1])
  )
}

export function isAdminOrMarketing(roles) {
  if (!roles) return false
  return (
    roles.includes(config.marketingGroups[0]) ||
    roles.includes(config.marketingGroups[1]) ||
    roles.includes(config.marketingGroups[2])
  )
}

export function isInCanEditPlanningViewGroup(roles) {
  if (!roles) return false
  return (
    roles.includes(config.editPlanningViewGroups[0]) ||
    roles.includes(config.editPlanningViewGroups[1]) ||
    roles.includes(config.editPlanningViewGroups[2]) ||
    roles.includes(config.editPlanningViewGroups[3])
  )
}

export function isInCanEditPlanningChannelGrid(roles) {
  if (!roles) return false
  return (
    roles.includes(config.editPlanningChannelGridGroups[0]) ||
    roles.includes(config.editPlanningChannelGridGroups[1]) ||
    roles.includes(config.editPlanningChannelGridGroups[2]) ||
    roles.includes(config.editPlanningChannelGridGroups[3])
  )
}

export function isMerchADGroup(roles) {
  if (!roles) return false
  return !isAdmin(roles) && roles.includes(config.adGroups[7])
}

export function isMarketing(roles) {
  if (!roles) return false
  return (
    roles.includes(config.marketingGroups[0]) ||
    roles.includes(config.marketingGroups[1])
  )
}

export function isAdminOrMerch(roles) {
  if (!roles) return false
  return roles.constructor === Array
    ? config.merchGroups.some((vendor) => roles.includes(vendor))
    : config.merchGroups.includes(roles)
}

export function isMerch(roles) {
  if (!roles) return false
  return (
    roles.includes(config.merchGroups[0]) ||
    roles.includes(config.merchGroups[1]) ||
    roles.includes(config.merchGroups[3])
  )
}

export function isAdminOrCreative(roles) {
  if (!roles) return false
  return config.creativeGroups.includes(roles)
}

export function isCreativeBCM(roles) {
  if (!roles) return false
  return roles.includes(config.creativeGroups[0])
}

export function isCreativeCopyWriter(roles) {
  if (!roles) return false
  return roles.includes(config.creativeGroups[1])
}

export function isCreativeArt(roles) {
  if (!roles) return false
  return roles.includes(config.creativeGroups[2])
}

export function isCreativeProducer(roles) {
  if (!roles) return false
  return roles.includes(config.creativeGroups[3])
}

export function isCreativeProjectManager(roles) {
  if (!roles) return false
  return roles.includes(config.creativeGroups[4])
}

export function isCreative(roles) {
  if (!roles) return false
  return (
    roles.includes(config.creativeGroups[0]) ||
    roles.includes(config.creativeGroups[1]) ||
    roles.includes(config.creativeGroups[2]) ||
    roles.includes(config.creativeGroups[3]) ||
    roles.includes(config.creativeGroups[4])
  )
}

export function isAdminOrCatalogCaptain(roles) {
  if (!roles) return false
  return (
    roles.includes(config.catalogCaptain[0]) ||
    roles.includes(config.catalogCaptain[1])
  )
}

export function isAdminOrSamples(roles) {
  if (!roles) return false
  return (
    roles.includes(config.samplesGroups[0]) ||
    roles.includes(config.samplesGroups[1])
  )
}

export function isSamples(roles) {
  if (!roles) return false
  return roles.includes(config.samplesGroups[0])
}

export function isVendor(roles) {
  if (!roles) return false
  return roles.constructor === Array
    ? config.vendorGroup.some((vendor) => roles.includes(vendor))
    : config.vendorGroup.includes(roles)
}

export function isProj(roles) {
  if (!roles) return false
  return config.vendorGroup.includes(roles)
}

export function isGroupedOfferUser(roles) {
  if (!roles) return false
  return (
    config.groupedOfferOnlyGroups.includes(roles) ||
    config.groupedOfferAllowedGroups.includes(roles)
  )
}

export function isReadOnlyUser(roles) {
  return (
    roles.includes(config.readGroups[0]) || roles.includes(config.readGroups[2])
  )
}

export function canAccessCatAmp(role) {
  if (!role) return false
  return config.catAmpViewGroups.includes(role)
}

export function canAccessPlanning(role) {
  if (!role) return false
  return config.planningViewGroups.includes(role)
}

export function canAccessExecution(role) {
  if (!role) return false
  return config.executionViewGroups.includes(role)
}

export function canAccessWeeklyAd(role) {
  if (!role) return false
  return (
    isAdmin(role) ||
    isCreative(role) ||
    isMarketing(role) ||
    isMerch(role) ||
    isReadOnlyUser(role)
  )
}

export function canAccessCatalog(role) {
  if (!role) return false
  if (isCreativeBCM(role)) return false
  return (
    isAdmin(role) ||
    isMerch(role) ||
    isCreative(role) ||
    isReadOnlyUser(role) ||
    isMarketing(role)
  )
}

export function canAccessVendor(role) {
  if (!role) return false
  return isAdmin(role) || isVendor(role) || isCreativeProjectManager(role)
}

export function canAccessDWAChangeHistory(role) {
  if (!role) return false
  return (
    isAdmin(role) ||
    isCreative(role) ||
    isMarketing(role) ||
    isMerch(role) ||
    isSamples(role) ||
    isReadOnlyUser(role)
  )
}

export function canAccessChangeSummary(role) {
  if (!role) return false
  return (
    isAdmin(role) ||
    isCreative(role) ||
    isMarketing(role) ||
    isMerch(role) ||
    isSamples(role) ||
    isReadOnlyUser(role)
  )
}

export function canAccessGroupedOffer(role) {
  if (!role) return false
  return isAdmin(role) || isGroupedOfferUser(role)
}

export function canAccessAdminCenter(role) {
  if (!role) return false
  return isAdmin(role) || isMarketing(role) || isCreative(role)
}

export function canEditExecution(role) {
  if (!role) return false
  return config.editExecutionViewGroups.includes(role)
}

export function canEditCatAmp(role) {
  if (!role) return false
  return config.editCatAmpGroups.includes(role)
}

export function canEditChangeSummary(role) {
  if (!role) return false
  return config.editChangeSummaryGroups.includes(role)
}

export function canEditImageTracking(role) {
  if (!role) return false
  return config.editImageTrackingGroups.includes(role)
}

export function canEditUpcomingCatalogs(role) {
  if (!role) return false
  return config.editUpcomingCatalogsGroups.includes(role)
}

export function canEditPastCatalogs(role) {
  if (!role) return false
  return config.editPastCatalogsGroups.includes(role)
}

export function canEditSystemAndProjectContacts(role) {
  if (!role) return false
  return config.editSystemAndProjectContacts.includes(role)
}

export function canEditWeeklyAdsUpcoming(role) {
  if (!role) return false
  return config.editWeeklyAdsUpcoming.includes(role)
}

export function canEditWeeklyAdsPast(role) {
  if (!role) return false
  return config.editWeeklyAdsPast.includes(role)
}

export function canEditCatalogPage(role) {
  if (!role) return false
  return config.editCatalogPage.includes(role)
}

export function findUserAccessLevel(targetRole, vendorRole, groupedUserRole) {
  let role = 'no-user-role'
  if (targetRole.length > 0) {
    role = targetRole[0]
  } else if (vendorRole.length > 0) {
    role = vendorRole[0]
  } else if (groupedUserRole.length > 0) {
    role = groupedUserRole[0]
  }
  return role
}

export function isValidCreativeHubADGroup(role) {
  if (!role) return false
  return config.adGroups.includes(role)
}
