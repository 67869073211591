import {
  Button,
  Table,
  Tooltip,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import {
  faImages,
  faLink,
  faKey,
  faPalette,
  faPlus,
  faChevronDown,
  faChevronRight,
  faCopy,
  faArrowRotateRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useContext, useState, useMemo } from 'react'

import OfferApi from '../../api/creative-hub/OfferApi'
import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import appConfig from '../../config/appConfig'
import { MerchantViewContext } from '../../context/Context'
import { EffectiveRoleContext } from '../../context/Context'
import { sortedModifiedOffers } from '../../creativeHubGlobal/util/OfferUtil'
import {
  activeOffers,
  sortUmbrellasByOrder,
} from '../../creativeHubGlobal/util/UmbrellaUtil'
import AddOfferArtPatch from '../../forms/AddOfferArtPatch'
import EditOfferArtPatch from '../../forms/EditOfferArtPatch'
import InlineEditInput from '../../planning/components/InlineEditInput'
import PyramidChip from '../../planning/components/PyramidChip'
import { copyUmbrellaLink } from '../../planning/util/UmbrellaUtil'
import {
  isAdminOrMarketing,
  isAdminOrMerch,
  isMerchADGroup,
} from '../../util/CheckRole'
import { handleFireflyClickInteraction } from '../../util/FireflyHelper'

import '../scss/umbrellaList.scss'

import OfferBucket from './OfferBucket'

const UmbrellaList = ({
  umbrellas = [],
  handleStoryUmbrellaMessage,
  setIsOfferDrawerOpen,
  setSelectedOffer,
  marketingChannelFilter = [],
  outcomePriorityId = '',
  pyramidNameId = '',
  pyramidType = '',
  projectId = '',
  projectDescription = '',
  projectName = '',
  storyId = '',
  storyName = '',
  projectStartDate,
  projectEndDate,
  selectedUmbrella,
  setSelectedUmbrella,
  selectedUmbrellaId,
  recentlyClickedOffer,
  session = {},
  setRecentlyClickedOffer,
  setSelectedUmbrellaId,
  selectedOffer,
  setIsItemImageModalOpen,
  setItemSelected,
  updatedItemImage,
  selectedStoryId,
}) => {
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [selectedUmbrellaIds, setSelectedUmbrellaIds] = useState([])
  const [offerElements, setOfferElements] = useState({})
  const [offerErrors, setOfferErrors] = useState({})
  const [activeOffersCount, setActiveOffersCount] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingUmbrella, setLoadingUmbrella] = useState()

  const offerApi = useMemo(() => new OfferApi(), [])
  const umbrellaApi = useMemo(() => new UmbrellaApi(), [])

  const includesUmbrellaId = (umbrellaId) => {
    if (!umbrellaId) return false
    return selectedUmbrellaIds.includes(umbrellaId)
  }

  const [addOfferArtPatch, setAddOfferArtPatch] = useState({
    show: false,
    umbrella: {},
  })
  const [addOfferToArtPatch, setAddOfferToArtPatch] = useState({
    show: false,
    umbrella: {},
  })
  const [editOfferArtPatch, setEditOfferArtPatch] = useState({
    show: false,
    umbrella: {},
  })

  const hasEditPrivelege = effectiveRole?.some(
    (role) =>
      (isAdminOrMarketing(role) || isAdminOrMerch(role)) &&
      !isMerchADGroup(role)
  )

  const getOffers = async (umbrella) => {
    const { planning_umbrella_id, ivy_umbrella_id } = umbrella
    setLoading(true)
    setLoadingUmbrella(planning_umbrella_id)
    offerApi
      .getOffers(ivy_umbrella_id, storyId, projectId)
      .then((res) => {
        if (res.error) throw new Error(res.error)
        setOfferElements((prevState) => ({
          ...prevState,
          [planning_umbrella_id]: res?.offer_responses,
        }))
        setActiveOffersCount(activeOffers(res?.offer_responses))
        setLoading(false)
        setLoadingUmbrella()
      })
      .catch((e) => {
        setOfferErrors((prevState) => ({
          ...prevState,
          [planning_umbrella_id]: e.message,
        }))
      })
  }

  const handleUmbrellaSelection = async (umbrella) => {
    const { planning_umbrella_id } = umbrella

    setSelectedUmbrella(umbrella)

    if (includesUmbrellaId(planning_umbrella_id)) {
      setSelectedUmbrellaIds(
        selectedUmbrellaIds.filter(
          (umbrellaIdItem) => umbrellaIdItem !== planning_umbrella_id
        )
      )
      return setOfferErrors((prevState) => ({
        ...prevState,
        [planning_umbrella_id]: null,
      }))
    }

    setSelectedUmbrellaIds((prevState) => [...prevState, planning_umbrella_id])
    getOffers(umbrella)
  }

  const handleRefreshUmbrella = async (umbrella) => {
    const { planning_umbrella_id, ivy_umbrella_id } = umbrella
    setLoading(true)
    setLoadingUmbrella(planning_umbrella_id)
    handleFireflyClickInteraction(
      'pageload',
      'Merchants: Refresh Umbrella',
      'success',
      session,
      {
        message: `pyramidType: ${pyramidType} - umbrella Id: ${ivy_umbrella_id} - offerIds: ${
          umbrella?.offers?.join() || ''
        }`,
        start_date: projectStartDate,
      }
    )
    umbrellaApi
      .refreshUmbrella({ ivy_umbrella_id })
      .then((res) => {
        getOffers(umbrella)
      })
      .catch((e) => {
        setLoading(false)
        setOfferErrors((prevState) => ({
          ...prevState,
          [planning_umbrella_id]: e.message,
        }))
      })
  }

  const putOffer = async (artPatches = []) => {
    const offerMap = new Map()
    offerElements[artPatches?.[0]?.umbrella_id].forEach((offer) =>
      offerMap.set(offer.id, offer)
    )
    const umbrellaId = artPatches?.[0]?.umbrella_id
    const response = await offerApi.updateOffers(artPatches)
    const updatedOffers = sortedModifiedOffers(response.data, offerMap)

    setOfferElements((prevState) => ({
      ...prevState,
      [umbrellaId]: updatedOffers,
    }))
  }
  const handleOfferUpdate = (umbrellaId, updatedOffers) => {
    setOfferElements((prevState) => ({
      ...prevState,
      [umbrellaId]: updatedOffers,
    }))
  }

  const handleDeleteOffer = (id, umbrellaId) => {
    offerApi.deleteOffer(id)
    const afterDeleteOffers = offerElements[umbrellaId].filter(
      (offer) => offer.id !== id
    )
    setOfferElements((prevState) => ({
      ...prevState,
      [umbrellaId]: afterDeleteOffers,
    }))
    setActiveOffersCount(activeOffers(afterDeleteOffers))
  }

  const displayedUmbrellas = sortUmbrellasByOrder(umbrellas)

  const handleAddPlaceholderOffer = async (
    umbrellaId,
    ivyUmbrellaId,
    umbrellaName
  ) => {
    await offerApi.createOffer({
      circle_offer: false,
      comments: '',
      copy: '',
      ivy_offer_id: -1,
      ivy_umbrella_id: ivyUmbrellaId,
      offer_order: activeOffersCount + 1,
      priority: pyramidNameId?.pyramidName,
      project_desc: projectDescription,
      project_id: projectId,
      project_name: projectName,
      pyramid_id: pyramidNameId?.pyramidId,
      pyramid_name: pyramidType ? pyramidType.toUpperCase() : '',
      story_id: storyId,
      story_name: storyName,
      umbrella_id: umbrellaId,
      umbrella_name: umbrellaName,
    })
    offerApi
      .postOffers({
        ivy_umbrella_id: ivyUmbrellaId,
        project_id: projectId,
        umbrella_id: umbrellaId,
      })
      .then((res) => {
        setOfferElements((prevState) => ({
          ...prevState,
          [umbrellaId]: res,
        }))
        setActiveOffersCount(activeOffers(res))
      })
      .catch((e) => console.log(e))
  }

  return (
    <>
      <Table>
        <Table.Body>
          {displayedUmbrellas.map((umbrella, ind) => {
            const {
              name,
              planning_umbrella_id,
              ivy_umbrella_id,
              promo_message,
            } = umbrella
            return (
              <UmbrellaItem
                key={'umbrella-' + ivy_umbrella_id}
                urlUmbrellaId={selectedUmbrellaId}
                selectedUmbrellaId={planning_umbrella_id}
                umbrella={umbrella}
                projectId={projectId}
                projectDescription={projectDescription}
                projectName={projectName}
                promoMessage={promo_message}
                projectStartDate={projectStartDate}
                projectEndDate={projectEndDate}
                pyramidNameId={pyramidNameId}
                pyramidType={pyramidType}
                ind={ind}
                loading={loading}
                loadingUmbrella={loadingUmbrella}
                isSelected={includesUmbrellaId(planning_umbrella_id)}
                hasEditPrivelege={hasEditPrivelege}
                handleUmbrellaSelection={handleUmbrellaSelection}
                handleRefreshUmbrella={handleRefreshUmbrella}
                handleStoryUmbrellaMessage={handleStoryUmbrellaMessage}
                handleAddPlaceholderOffer={handleAddPlaceholderOffer}
                handleDeleteOffer={handleDeleteOffer}
                setEditOfferArtPatch={setEditOfferArtPatch}
                setIsOfferDrawerOpen={setIsOfferDrawerOpen}
                setAddOfferArtPatch={setAddOfferArtPatch}
                setSelectedOffer={setSelectedOffer}
                offerElements={offerElements?.[planning_umbrella_id]}
                offerErrors={offerErrors[planning_umbrella_id]}
                ivyUmbrellaId={ivy_umbrella_id}
                outcomePriorityId={outcomePriorityId}
                storyId={storyId}
                storyName={storyName}
                name={name}
                recentlyClickedOffer={recentlyClickedOffer}
                setRecentlyClickedOffer={setRecentlyClickedOffer}
                setSelectedUmbrellaId={setSelectedUmbrellaId}
                marketingChannelFilter={marketingChannelFilter}
                handleOfferUpdate={handleOfferUpdate}
                selectedOffer={selectedOffer}
                setIsItemImageModalOpen={setIsItemImageModalOpen}
                setItemSelected={setItemSelected}
                updatedItemImage={updatedItemImage}
                selectedStoryId={selectedStoryId}
              />
            )
          })}
        </Table.Body>
      </Table>
      <AddOfferArtPatch
        addOfferArtPatch={addOfferArtPatch}
        setAddOfferArtPatch={setAddOfferArtPatch}
        putOffer={putOffer}
      />
      <EditOfferArtPatch
        editOfferArtPatch={editOfferArtPatch}
        setEditOfferArtPatch={setEditOfferArtPatch}
        putOffer={putOffer}
        addOfferToArtPatch={addOfferToArtPatch}
        setAddOfferToArtPatch={setAddOfferToArtPatch}
      />
    </>
  )
}

const UmbrellaItem = ({
  selectedUmbrellaId,
  umbrella = {},
  outcomePriorityId = '',
  storyId = '',
  storyName = '',
  projectId = '',
  projectDescription = '',
  projectName = '',
  promoMessage,
  projectStartDate,
  projectEndDate,
  pyramidType = '',
  pyramidNameId = '',
  ind,
  loading,
  loadingUmbrella,
  isSelected,
  hasEditPrivelege,
  handleAddPlaceholderOffer,
  handleUmbrellaSelection,
  handleRefreshUmbrella,
  handleStoryUmbrellaMessage,
  setEditOfferArtPatch,
  handleDeleteOffer,
  setIsOfferDrawerOpen,
  setAddOfferArtPatch,
  setSelectedOffer,
  offerElements,
  ivyUmbrellaId,
  name,
  offerErrors,
  urlUmbrellaId,
  recentlyClickedOffer,
  setRecentlyClickedOffer,
  setSelectedUmbrellaId,
  marketingChannelFilter = [],
  handleOfferUpdate,

  selectedOffer,
  setIsItemImageModalOpen,
  setItemSelected,
  updatedItemImage,
  selectedStoryId,
}) => {
  const {
    after_project_ko = false,
    invalid_dates = false,
    key = false,
    merchant_category = {},
    planning_umbrella_id,
    status = '',
    umbrella_order,
  } = umbrella

  const umbrellaObj = {
    ...umbrella,
    project_id: projectId,
    promoMessageUpdate: true,
    umbrella_id: planning_umbrella_id,
  }

  const deactivated = status === 'DEACTIVATED'

  const [umbrellaPromoMessage, setUmbrellaPromoMessage] = useState(
    umbrellaObj.promo_message
  )

  const handlePromoChange = (promoMessage) => {
    setUmbrellaPromoMessage(promoMessage)
    handleStoryUmbrellaMessage({ ...umbrella, promoMessage }, storyId)
  }

  const config = appConfig()
  const { linkedUmbrellas: enterpriseLinkedUmbrellas } =
    useContext(MerchantViewContext)
  const isEnterpriseLinked = enterpriseLinkedUmbrellas?.find(
    (entLinkedUmbrella) => entLinkedUmbrella.ivy_umbrella_id === ivyUmbrellaId
  )

  const copyUmbrellaLinkOnclick = (e) => {
    e.stopPropagation()
    copyUmbrellaLink({ ivyUmbrellaId, name })
  }

  useEffect(() => {
    if (urlUmbrellaId === selectedUmbrellaId) {
      handleUmbrellaSelection(umbrella)
    }
    // eslint-disable-next-line
  }, [])

  const handleOpenCreativeSummary = (umbrella) => {
    const channelIds = marketingChannelFilter.map((channel) => channel.id)
    let creativeSummaryUrl = `${config.ui.url}/execution/creativeSummary/${projectId}/${selectedStoryId}/${ivyUmbrellaId}/${umbrella_order}`
    if (channelIds.length > 0) {
      creativeSummaryUrl = `${creativeSummaryUrl}?channelIds=${channelIds}`
    }
    window.open(creativeSummaryUrl, '_blank')
  }

  return (
    <div
      key={`${ind}-${selectedUmbrellaId}`}
      className="execution-umbrellaCard"
    >
      <Table.Row
        className="umbrella-item-container"
        onClick={() =>
          handleUmbrellaSelection({
            ...umbrella,
            promo_message: umbrellaPromoMessage,
          })
        }
        data-testid="umbrellaContainer"
      >
        <Table.Data xs={1}>
          <FontAwesomeIcon icon={isSelected ? faChevronDown : faChevronRight} />
        </Table.Data>
        <Table.Data xs={2} data-cy={`umbrellaItem${ind}`}>
          <span className={after_project_ko ? 'umbrella_name_background' : ''}>
            UMBRELLA {ind + 1}
          </span>
        </Table.Data>
        <Table.Data
          xs={isSelected ? 5 : 6}
          className={deactivated ? 'deactivatedName' : 'umbrella-item-title'}
        >
          {pyramidType !== 'ent' && isEnterpriseLinked ? (
            <Tooltip
              content="This umbrella is also linked to an Enterprise Guest Outcome"
              location="top-left"
              style={{ zIndex: 1 }}
            >
              <FontAwesomeIcon
                icon={faLink}
                className="fa-icon linked"
                size="lg"
              />
            </Tooltip>
          ) : null}
          {pyramidType === 'ent' ? (
            <PyramidChip
              groupName={merchant_category?.group_name}
              isKey={key}
            />
          ) : (
            <>
              {key ? (
                <FontAwesomeIcon
                  icon={faKey}
                  className="fa-duotone key"
                  size="lg"
                />
              ) : null}
            </>
          )}
          <a
            className={deactivated ? 'deactivatedLink' : null}
            target="_blank"
            href={`${config.ivy.uiUrl}umbrella/${ivyUmbrellaId}`}
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <span className={invalid_dates ? 'invalidDates' : null}>
              {' '}
              {name}{' '}
            </span>
            {invalid_dates ? (
              <span className="invalidDatesText">INVALID DATES </span>
            ) : null}
          </a>
          {deactivated ? ' - DEACTIVATED' : null}
          <FontAwesomeIcon
            className="copy-btn"
            icon={faCopy}
            onClick={copyUmbrellaLinkOnclick}
          />
        </Table.Data>
        <Table.Data xs={1} style={{ padding: '1px' }}>
          {isSelected ? (
            <Tooltip
              location="left"
              content="Refresh"
              style={{ float: 'left', zIndex: 1 }}
            >
              <Button
                aria-label="Refresh Umbrella"
                iconOnly
                onClick={(event) => {
                  event.stopPropagation()
                  handleRefreshUmbrella({
                    ...umbrella,
                  })
                }}
                style={{
                  verticalAlign: 'top',
                }}
              >
                <FontAwesomeIcon
                  className="fa-solid fa-rotate-right"
                  style={{ marginRight: 'auto' }}
                  icon={faArrowRotateRight}
                />
              </Button>
            </Tooltip>
          ) : null}
        </Table.Data>
        <Table.Data xs={1} style={{ padding: '1px' }}>
          {isSelected ? (
            <Tooltip
              location="left"
              content="Link Art Patch "
              style={{ float: 'left', zIndex: 1 }}
            >
              <Button
                aria-label="set offer art patch"
                iconOnly
                onClick={(event) => {
                  event.stopPropagation()
                  setAddOfferArtPatch({
                    offerElements,
                    show: true,
                    umbrella,
                  })
                }}
                style={{
                  verticalAlign: 'top',
                }}
                disabled={!hasEditPrivelege}
              >
                <FontAwesomeIcon
                  className="fa-stack-1x hc-clr-contrast"
                  style={{ marginRight: 'auto' }}
                  icon={faPalette}
                />
                <FontAwesomeIcon
                  className="fa-stack-1x hc-clr-success"
                  style={{ left: 15, marginRight: 'auto', top: 15 }}
                  size="xs"
                  icon={faPlus}
                />
              </Button>
            </Tooltip>
          ) : null}
        </Table.Data>
        {isSelected && hasEditPrivelege ? (
          <Table.Data xs={1}>
            <Tooltip
              content="Create temporary placeholder offer"
              location="left"
              style={{ zIndex: 1 }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                onClick={(event) => {
                  event.stopPropagation()
                  if (hasEditPrivelege) {
                    handleAddPlaceholderOffer(
                      selectedUmbrellaId,
                      ivyUmbrellaId,
                      name
                    )
                  }
                }}
              />
            </Tooltip>
          </Table.Data>
        ) : null}
        <Table.Data xs={1}>
          <Tooltip
            content="Creative Summary"
            location="left"
            style={{ zIndex: 1 }}
          >
            <FontAwesomeIcon
              icon={faImages}
              className="labelledIcon clickable"
              color="#000000"
              size="lg"
              onClick={(event) => {
                event.stopPropagation()
                handleOpenCreativeSummary()
              }}
            />
          </Tooltip>
        </Table.Data>
      </Table.Row>
      {isSelected ? (
        <Table.Row>
          <Table.Data xs={12} className="umbrell-promo-container">
            <InlineEditInput
              data={umbrellaObj}
              field="promoMessage"
              isDeactivated={!hasEditPrivelege}
              handlePromoChange={handlePromoChange}
              value={umbrellaPromoMessage}
              multiline={true}
              label="Promo Message:"
              placeholder="Enter promo message here, if applicable to all offers below."
            />
          </Table.Data>
        </Table.Row>
      ) : null}
      <Table.Row
        key={isSelected + 'selected' + selectedUmbrellaId + ind}
        className={`expand-row-transition${
          isSelected ? ' row-height-expand' : ' row-height-collapse'
        }`}
      >
        {loading && selectedUmbrellaId === loadingUmbrella ? (
          <ProgressBar indeterminate />
        ) : (
          <Table.Data
            key={'offers' + selectedUmbrellaId}
            xs={12}
            className="hc-pa-none"
          >
            <OfferBucket
              offerElements={offerElements}
              offerErrors={offerErrors}
              umbrella={umbrella}
              umbrellaId={selectedUmbrellaId}
              setEditOfferArtPatch={setEditOfferArtPatch}
              setIsOfferDrawerOpen={setIsOfferDrawerOpen}
              setSelectedOffer={setSelectedOffer}
              handleDeleteOffer={handleDeleteOffer}
              outcomePriorityId={outcomePriorityId}
              projectId={projectId}
              projectStartDate={projectStartDate}
              projectEndDate={projectEndDate}
              storyId={storyId}
              storyName={storyName}
              pyramidNameId={pyramidNameId}
              pyramidType={pyramidType}
              projectDescription={projectDescription}
              projectName={projectName}
              recentlyClickedOffer={recentlyClickedOffer}
              setRecentlyClickedOffer={setRecentlyClickedOffer}
              setSelectedUmbrellaId={setSelectedUmbrellaId}
              marketingChannelFilter={marketingChannelFilter}
              handleOfferUpdate={handleOfferUpdate}
              selectedOffer={selectedOffer}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              updatedItemImage={updatedItemImage}
              selectedStoryId={selectedStoryId}
            />
          </Table.Data>
        )}
      </Table.Row>
    </div>
  )
}

export default UmbrellaList
